import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: ['prices', 'global', 'register', 'settings'],
    },
  ],
})
export class FooterComponent implements OnInit {
  public route = inject(Router);
  ngOnInit(): void {
    // console.warn('init footer');
  }
}
