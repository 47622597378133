<!-- <mat-toolbar class="footer" class="footer"> -->

<div id="page_footer" class="footer" *transloco="let t">
  <div class="content">
    <div class="socialButtonsWrapper footerInner">
      <div class="socialButtons">
        <a class="mr-5" href="https://twitter.com/kennwerte" target="_blank" rel="noopener noreferrer">
          <mat-icon class="socialButton"
            ><img class="mat-img" src="/assets/images/twitter.svg" width="18" height="18"
          /></mat-icon>
        </a>
        <a
          class="mr-5"
          style="padding-left: 5px"
          href="https://www.youtube.com/channel/UCiyMokTAiyHhoN6pQss5hiA/featured"
          target="_blank"
          rel="noopener noreferrer"
        >
          <mat-icon class="socialButton">
            <img class="mat-img" src="/assets/images/youtube.svg" width="18" height="18" />
          </mat-icon>
        </a>
        <a class="mr-5" href="https://www.linkedin.com/company/kennwerte-ag/" target="_blank" rel="noopener noreferrer">
          <mat-icon class="socialButton">
            <img class="mat-img" src="/assets/images/linkedin.svg" width="18" height="18" />
          </mat-icon>
        </a>
        <a
          class="mr-5"
          style=""
          href="https://www.facebook.com/Kennwertech-257242684929105"
          target="_blank"
          rel="noopener noreferrer"
        >
          <mat-icon class="socialButton"
            ><img class="mat-img" src="/assets/images/facebook.svg" width="18" height="18" />
          </mat-icon>
        </a>
        <!-- <a class="socialButton" ng-if="false"><i class="fa-brands fa-google-plus-g"></i></a>
                    <a class="socialButton" ng-if="false"><i class="fa-brands fa-skype"></i></a> -->
        <a class="clearFloat"></a>
      </div>
    </div>
    <div class="footerTextWrapper footerInner">
      <div class="footerText">
        <div [innerHtml]="t('global.footer.text-1')"></div>
        <div [innerHtml]="t('global.footer.text-2')"></div>
      </div>
    </div>
    <div class="contactButtonWrapper footerInner">
      <span>
        <!--<a ui-sref="terms-of-use" data-translate="footer.terms-of-use" class="footerText">AGB & Datenschutz</a>-->
        <a (click)="route.navigate(['/jobs'])" target="_blank" class="footerText">{{ t("global.footer.jobs") }} </a>
        &nbsp;
        <a href="../../../pdf/{{ '' | currentLanguage }}/AGB_keeValue_AG.pdf" target="_blank" class="footerText"
          >{{ t("global.footer.conditions") }}
        </a>
        &nbsp;
        <label class="footerText clearLabel" data-translate="footer.and"></label>
        &nbsp;
        <a
          href="../../../pdf/{{ '' | currentLanguage }}/Datenschutz_keeValue_AG.pdf"
          target="_blank"
          class="footerText"
          >{{ t("global.footer.data-privacy") }}</a
        >
      </span>
      <span class="ml-20"
        ><a routerLink="contact" class="footerText">{{ t("global.footer.contact") }}</a>
      </span>
    </div>
    <div class="clearFloat"></div>
  </div>
</div>

<!-- </mat-toolbar> -->
